.spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;

    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #333;
    border-radius: 100%;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0.0)
    }

    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }

    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}







/* Modal.css */
.modal-overlay {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.modal-overlay.show {
    opacity: 1;
}


/* tooltip code for hover assistant rating details */
.tooltip-container {
    /* GitHub green color */
    position: relative;
    cursor: pointer;
    display: inline-block;
    
}

.tooltip {
    --background-tooltip: #161616;
    /* Default background color for tooltip */
    position: absolute;
    
    bottom: 150%;
    /* Position the tooltip above the icon */
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5em;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    background: var(--background-tooltip);
    color: white;
    /* Text color */
    border-radius: 5px;
    min-width: 200px;
    /* Set max-width to ensure text wraps */
    font-size: 12px;
    text-align: center;
    visibility: hidden;
    white-space: normal;
    /* Ensure text wraps within the tooltip */
    word-wrap: break-word;
    /* Break long words if necessary */
    z-index: 1;
}

.tooltip::before {
    position: absolute;
    content: "";
    height: 0.6em;
    width: 0.6em;
    top: 100%;
    /* Position the arrow at the bottom of the tooltip */
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    background: var(--background-tooltip);
    /* Use the same background color as the tooltip */
}

.tooltip-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}


/* loader bouncy square */

.bounce {
    width: 48px;
    height: 48px;
    margin: auto;
    position: relative;
}

.bounce:before {
    content: '';
    width: 48px;
    height: 5px;
    background: #f0808050;
    position: absolute;
    top: 60px;
    left: 0;
    border-radius: 50%;
    animation: shadow324 0.5s linear infinite;
}

.bounce:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #f08080;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
    15% {
        border-bottom-right-radius: 3px;
    }

    25% {
        transform: translateY(9px) rotate(22.5deg);
    }

    50% {
        transform: translateY(18px) scale(1, .9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }

    75% {
        transform: translateY(9px) rotate(67.5deg);
    }

    100% {
        transform: translateY(0) rotate(90deg);
    }
}

@keyframes shadow324 {

    0%,
    100% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.2, 1);
    }
}

/* save changes loader */

.loader {
    color: black;
    font-size: 20px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    transform: translateZ(0);
    animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes mltShdSpin {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em,
            0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em,
            0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em,
            -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
            -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
            -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
            -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
            -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
            -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
            0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes round {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}




/* checkmark */
* {
    padding: 0;
    margin: 0
}

.wrapper {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142
    }
}




/* typing bubble */

.typing {
    width: 5em;
    height: 2em;
    position: relative;
    padding: 10px;
    margin-left: 5px;
    background-color: rgb(243 244 246);
    border-radius: 20px;
}

.typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: #8d8c91;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
    animation-delay: 0s;
}

.typing__dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes loadingFade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0;
    }
}







/* FAQ.css */
.faq-content {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}








/* box glow text */



/* glow text */
.glow-text {
    position: relative;
    padding: 10px;
    border-radius: 8px;
    z-index: 1;
    /* Ensure the text is above the glow */
    background: hsl(0, 0%, 100%);
    /* Ensure the text has a white background */
}

.glow-text::before {
    position: absolute;
    content: "";
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -2;
    border-radius: inherit;
    filter: blur(12px);
    opacity: 0.4;
    background: linear-gradient(to left,
            #ff5770,
            #e4428d,
            #c42da8,
            #9e16c3,
            #6501de,
            #9e16c3,
            #c42da8,
            #e4428d,
            #ff5770);
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
}

.glow-text::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: inherit;
    background: hsl(0, 0%, 100%);
    /* White background behind the text */
}

@keyframes animateGlow {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 200% 50%;
    }
}



.glow-input {
    position: relative;
    padding: 10px;
    border-radius: 8px;
    z-index: 1;
    /* Ensure the input is above the glow */
}

.glow-input::before {
    position: absolute;
    content: "";
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -2;
    border-radius: inherit;
    filter: blur(12px);
    opacity: 0.4;
    background: linear-gradient(to left,
            #ff5770,
            #e4428d,
            #c42da8,
            #9e16c3,
            #6501de,
            #9e16c3,
            #c42da8,
            #e4428d,
            #ff5770);
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
}

.glow-input::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: inherit;
    background: hsl(0, 0%, 100%);
    /* White background behind the input */
}

@keyframes animateGlow {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 200% 50%;
    }
}





.landing-glow-button {
    position: relative;
    /* changed from absolute */
    border: none;
    color: #ffffff;
    width: 8em;
    /* Increased width */
    height: 2em;
    line-height: 2em;
    text-align: center;
    background: linear-gradient(90deg,
            #ff5770,
            #e4428d,
            #c42da8,
            #9e16c3,
            #6501de,
            #9e16c3,
            #c42da8,
            #e4428d,
            #ff5770);
    background-size: 300%;
    border-radius: 30px;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 1;
}

.landing-glow-button:hover {
    animation: animation 8s linear infinite;
    border: none;
}

@keyframes animation {
    0% {
        background-position: 0%;
    }

    100% {
        background-position: 400%;
    }
}

.landing-glow-button::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -15px;
    right: -15px;
    bottom: -4px;
    z-index: -1;
    background: linear-gradient(90deg,
            #ff5770,
            #e4428d,
            #c42da8,
            #9e16c3,
            #6501de,
            #9e16c3,
            #c42da8,
            #e4428d,
            #ff5770);
    background-size: 400%;
    border-radius: 35px;
    transition: 1s;
    /* box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1); */
}

.landing-glow-button:hover::before {
    filter: blur(20px);
}
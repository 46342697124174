/* .bg-login-image {
    @apply bg-no-repeat bg-cover bg-center;
    background-image: url('./assets/House-on-Green-Grass.jpeg');
} */

.bg-login-image {
    @apply bg-no-repeat bg-cover bg-center;
    background-image: url('./assets/snow-house.jpeg');
}

.bg-landing-image {
    @apply bg-no-repeat bg-cover bg-center;
    background-image: url('./assets/landing-house.png');
}

/* .bg-house-image {
    @apply bg-no-repeat bg-cover bg-center;
    background-image: url('./assets/Luxury Airbnb Sayulita Mexico.jpg');
} */

/* Add to your CSS file or within a <style> tag */
.nav-link {
    position: relative;
    display: flex;
    /* Additional Tailwind classes like padding, margin, text, and background handling */
}

.nav-link::before {
    content: '';
    position: absolute;
    left: 0;
    /* Align to the left edge of the button */
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    /* Line width */
    height: 70%;
    /* Adjust the height as needed */
    /* background-color: #f6656c; */
    background-color: #FF5A5F;
    /* Line color */
    border-top-right-radius: 10px;
    /* Round the top right corner */
    border-bottom-right-radius: 10px;
    /* Round the bottom right corner */
    transition: height 0.5s ease-in-out;
    /* Smooth transition */
}

/* Ensure that hover effect applies only when not active */
.nav-link:not(.nav-link-active):hover::before {
    height: 30%;
    /* Full height on hover for instant effect */
    transition: height 0s;
    /* No transition delay on hover */
}

.nav-link-active::before {
    height: 70%;
    /* Full height when active */
    transition: height 0.5s ease-in-out;
    /* Transition when becoming active */
}

.nav-link:not(.nav-link-active)::before {
    height: 0;
    /* Remove the line when another button is clicked */
    transition: height 0s;
    /* Instant removal */
}



/* CSS */
.button-64 {
    align-items: center;
    background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    width: 100%;
    /* Sets the button width to fill the container */
    min-width: 140px;
    /* Ensures button is not smaller than 140px */
    padding: 4px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
}


.short-height {
    height: 1px;
    /* or any other value less than 0.25rem */
}


/* Targets the scrollbar itself */
::-webkit-scrollbar {
    width: 6px;
    /* Thin scrollbar width */
    height: 6px;
    /* Thin scrollbar height for horizontal bars */
}

/* Targets the draggable part of the scrollbar */
::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    /* Color of the scrollbar */
    border-radius: 4px;
    /* Rounded corners of the scrollbar */
}

/* Targets the part of the scrollbar track */
::-webkit-scrollbar-track {
    background: transparent;
    /* No background color */
}


/* landing header try it */


.try-it {
    background: hsl(0, 0%, 100%);
    padding: 5px 5px;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .01);

    &::after {
        position: absolute;
        content: "";
        top: 15px;
        left: 0;
        right: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
        transform: scale(0.95) translateZ(0);
        filter: blur(12px);
        opacity: 0.4;
        /* New property for subtler effect */
        background: linear-gradient(to left,
                #ff5770,
                #e4428d,
                #c42da8,
                #9e16c3,
                #6501de,
                #9e16c3,
                #c42da8,
                #e4428d,
                #ff5770);
        background-size: 200% 200%;
        animation: animateGlow 1.25s linear infinite;
    }
}

@keyframes animateGlow {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 200% 50%;
    }
}





.create-button {
    position: relative;
    /* changed from absolute */
    border: none;
    color: #ffffff;
    width: 5em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    background: linear-gradient(90deg,
                #ff5770,
                #e4428d,
                #c42da8,
                #9e16c3,
                #6501de,
                #9e16c3,
                #c42da8,
                #e4428d,
                #ff5770);
    background-size: 300%;
    border-radius: 30px;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 1;
}

.create-button:hover {
    animation: animation 8s linear infinite;
    border: none;
}

@keyframes animation {
    0% {
        background-position: 0%;
    }

    100% {
        background-position: 400%;
    }
}

.create-button::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -15px;
    right: -15px;
    bottom: -4px;
    z-index: -1;
    background: linear-gradient(90deg,
            #ff5770,
            #e4428d,
            #c42da8,
            #9e16c3,
            #6501de,
            #9e16c3,
            #c42da8,
            #e4428d,
            #ff5770);
    background-size: 400%;
    border-radius: 35px;
    transition: 1s;
    /* box-shadow: 0 10px 12px rgba(0, 0, 0, 0.1); */
}

.create-button:hover::before {
    filter: blur(20px);
}



.adjustable-create-button {
    position: relative;
    border: none;
    color: #ffffff;
    padding: 0.5em 1em;
    /* Adjusted padding for content fit */
    text-align: center;
    background: linear-gradient(90deg,
            #ff5770,
            #e4428d,
            #c42da8,
            #9e16c3,
            #6501de,
            #9e16c3,
            #c42da8,
            #e4428d,
            #ff5770);
    background-size: 300%;
    border-radius: 30px;
    cursor: pointer;
    z-index: 1;
    text-transform: none;
    /* Removed uppercase */
    font-family: inherit;
    /* Ensures font is inherited */
    font-size: inherit;
    /* Ensures font size is inherited */
    line-height: 1.5;
    /* Adjusted line-height for better alignment */
}

.adjustable-create-button:hover {
    animation: animation 8s linear infinite;
    border: none;
}

@keyframes animation {
    0% {
        background-position: 0%;
    }

    100% {
        background-position: 400%;
    }
}

.adjustable-create-button::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -15px;
    right: -15px;
    bottom: -4px;
    z-index: -1;
    background: linear-gradient(90deg,
            #ff5770,
            #e4428d,
            #c42da8,
            #9e16c3,
            #6501de,
            #9e16c3,
            #c42da8,
            #e4428d,
            #ff5770);
    background-size: 400%;
    border-radius: 35px;
    transition: 1s;
}

.adjustable-create-button:hover::before {
    filter: blur(20px);
}
.text-container {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    font-size: 96px;
    color: white;
    opacity: 0.8;
    user-select: none;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}

.gradient-bg {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background: linear-gradient(40deg, #6c00a2, #001152);
}

.gradients-container {
    width: 100%;
    height: 100%;
    /* filter: url(#goo) blur(40px); */
}

.g1,
.g2,
.g3,
.g4,
.g5,
.interactive {
    position: absolute;
    width: 80%;
    height: 80%;
    top: calc(50% - 40%);
    left: calc(50% - 40%);
    background: radial-gradient(circle at center, rgba(18, 113, 255, 0.8) 0, transparent 50%);
    mix-blend-mode: hard-light;
    animation: moveVertical 25s ease infinite;
    /* Adjusted duration for a more dynamic effect */
}

.g2 {
    background: radial-gradient(circle at center, rgba(221, 74, 255, 0.8) 0, transparent 50%);
    animation: moveInCircle 15s reverse infinite;
    /* Faster and reverse for dynamic interaction */
}

.g3 {
    background: radial-gradient(circle at center, rgba(100, 220, 255, 0.8) 0, transparent 50%);
    animation: moveInCircle 35s linear infinite;
    /* Slower for a varied effect */
}

.g4 {
    background: radial-gradient(circle at center, rgba(200, 50, 50, 0.7) 0, transparent 50%);
    animation: moveHorizontal 30s ease infinite;
    /* Extended range */
}

.g5 {
    background: radial-gradient(circle at center, rgba(180, 180, 50, 1) 0, transparent 50%);
    animation: moveInCircle 15s ease infinite;
    /* Making this more lively */
}

@keyframes moveInCircle {
    0% {
        transform: translate(-10%, -10%) rotate(0deg);
    }

    50% {
        transform: translate(10%, 10%) rotate(180deg);
    }

    100% {
        transform: translate(-10%, -10%) rotate(360deg);
    }
}

@keyframes moveVertical {
    0% {
        transform: translateY(-100%);
    }

    50% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(-100%);
    }
}

@keyframes moveHorizontal {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}





/* loading animation css 9 blocks */

.sk-cube-grid {
    width: 40px;
    height: 40px;
    margin: 100px auto;
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #ffffff;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {

    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }

    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {

    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }

    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}